body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Style it as you wish */
.apple-pay {
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.apple-pay__btn {
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  -apple-pay-button-style: black;
  cursor: pointer;
}

.apple-pay__btn:hover {
  opacity: 0.9;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 40%;
}
